import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgmCoreModule, MapsAPILoader, AgmMap} from "@agm/core";
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { GlobalConstants } from '../global-constants';
import { SPECIAL_DATES } from './special-dates';


declare var google: any;
declare var $: any;


@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.less']
})


export class BookingComponent implements OnInit {
  minDate: Date;
  minTime: any;
  mode:String;
  vehicletype: String;
  datetime:boolean;
  isoutstation:boolean;
  totalMiles:any;
  charge:any;
  waitingcharge:any;
  roundTripwaitingcharge:any;
  roundtripWaitings: 0;
  totalPrice:any;
  rate:any;
  totalWaititng:any;
  chargeRound:any;
  waitingchargeRound:any;
  waitingdayscharge=0;
  rwaiting=0;
  waitingforMint=0.333333;
  stopslist:any;
  addstops: any;
  ttype:any;
  btype:any;
  hide = true;
  otp:any;
  otpShow:Boolean;
  drivers:[];
  slctli:any;
  checkrun=0;
  checked = false;
  checked2 = false;
  checkfavdrvr =false;
  promocode: string = '';
  promocodeApplied: any;
  promocodeID: any;
  promocodeType: 0;
  promocodeAmount: any;
  isButtonClicked = false;
  SpecialDayMessage: string = ''; 
  constructor(private route:Router,private http: HttpClient,private ref: ChangeDetectorRef, public datepipe: DatePipe) {
    ref.detach();
    setInterval(() => { this.ref.detectChanges();
      
    }, 0);
   }
   public addresses: any[] = [
    {
      id: 1,
      address: '',
    },
  ];
  ngOnInit(): void {
    this.minDate = new Date();
    // this.vehicletype="";
    this.datetime=true;
    this.isoutstation=true;
    this.totalMiles=localStorage.getItem("totaldistance");
    this.totalPrice=localStorage.getItem("totalprice");
    this.waitingchargeRound=localStorage.getItem("waitingchargeround");
    this.chargeRound=localStorage.getItem("chargeround");
    this.totalWaititng=0;
    this.rate=2.50;
    this.promocodeAmount=0;
    let step0:any;
    this.addstops=0;
    this.otpShow=false;
    $("#step2error").hide();
    $(".booknow").hide();
    $("#error5").hide();
    $("#otp").hide();
    $(".driverprefer").hide();
    this.editbSummary();
    this.bookingsummary();
    this.checkchanged();
    let step1 = JSON.parse(localStorage.getItem("booking_step1") || '{}');
    console.log('step1');
    console.log(step1);
    if (localStorage.hasOwnProperty("customer_id")) {
      $(".notregistered").hide(); 
      $(".registeresproceed").show();
    } 
    else{
      $(".registeresproceed").hide();
      $(".notregistered").show(); 
    }
    if (localStorage.getItem("booking_step1") === null){
      this.route.navigate(['/']);
    }
    else{
      if(step1.data[0].booking_type != "schedule"){
        $("#scheduled").hide();
        this.datetime=false;
      }
      if(step1.data[0].trip_type != "outstation"){
        $("#outstation-field").hide();
        this.isoutstation=false;
      }
      
    }
    step0 = JSON.parse(localStorage.getItem("booking_step1") || '{}');
    let stopsall = step0.data[0].stops;
    var stops = [];
    for (let i = 0; i < stopsall.length; i++) {
      this.totalWaititng=this.totalWaititng+parseInt(stopsall[i].stop_waiting);
  }
    this.calculate();
    $("#updatebtn").hide();
    setTimeout(() => $("#trigcalc").remove(),2500);
    
  }
  // public startdatechanged(date:any){
  //  if(this.datepipe.transform(date, 'MM/dd/yyyy')==this.datepipe.transform(this.minDate, 'MM/dd/yyyy')){
  

  //      this.minTime = this.minDate.getTime() + (2*60*60*1000);
  //      $('.timepkr').val('');
  //   }
  //   else{
  //      this.minTime ="";

  //   }
  // } 
  get triggerCalculate(){
    this.calculate();
    this.checkrun=this.checkrun+1;
    return 'calculating';
  }
  addAddress() {
    this.addresses.push({
      id: this.addresses.length + 1,
      address: '',
    });
    // alert( (<HTMLInputElement>document.getElementById("mat-input-9")).value);
  }

  

  removeAddress(i: number) {
    this.addresses.splice(i, 1);
  }

  checkPromocode() {
    const headers = { 'Content-type': 'application/json'};
    const body = {
      promocode:this.promocode,
    };
    this.http.post(GlobalConstants.apiURL+'promocode_check', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if (Response.status === "success") {
            this.promocodeType = Response.data.discount_type;
            this.promocodeID = Response.data.id;
            this.promocodeApplied = Response.data.code;
            let discval=parseInt(Response.data.discount_value );
            if(Response.data.discount_type === "fixed" )
            {
              this.promocodeAmount = discval;
            }
            else{
              this.promocodeAmount = (this.totalPrice * discval) / 100;
            }
            
        //  this.totalPrice= (this.totalPrice) - (this.promocodeAmount);
         this.calculate();
           
          } else {
            Swal.fire('Sorry', Response.message, 'error');
          }
        }
        


      });
  }
  public generateOtp(mobile:any){
    this.otp = Math.floor(100000 + Math.random() * 900000);
    // console.log(this.otp);
    
    // mobile=''+GlobalConstants.countrycode+''+mobile;
    mobile=mobile;
    const headers = { 'Content-type': 'application/json'};
    const body = {
      title: 'otp',
      message: ''+this.otp+' is your OTP for Yoder Toter',
      customer_phone:mobile,
    };
    this.http.post(GlobalConstants.apiURL+'sendsms', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if(Response.status=="success"){
       
          }
        }


      });
  }
  public login(phone:any,password:any){
    console.log("login clicked");
    if(phone==""|| phone==null || phone==undefined || password==""|| password==null || password==undefined  ){
      $(".errormod").html(" All fields are required");
      $(".errormod").show();
    }
    else{
      $(".errormod").hide();

      let logindat = {
        data: [
          {
            phone: phone,
            password: password,
          },
        ],
      };
      const headers = { 'Content-type': 'application/json'};
    const body = {
      title: 'Login',
      logindat: logindat,
    };
    this.http.post(GlobalConstants.apiURL+'checkcustomer', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          if(Response.status=="success"){
            localStorage.setItem('customer_id', Response.customer_id);
            localStorage.setItem('customer_name', Response.customer_name);
            this.ngOnInit();
            if(this.slctli.length>0){
              $(".driverprefer").show();
            }
          }
          else{
            $(".errormod").html("Please check phone and password");

            $(".errormod").show();
          }
         
        }


      });
    }
  }   
  public checkOTP(mobile: any, otp: any, callback: (result: boolean) => void) {
    const headers = { 'Content-type': 'application/json' };
    const body = {
        mobile: mobile,
        otp: otp
    };
    
    this.http.post(GlobalConstants.apiURL + 'otp_check', body, { headers })
        .subscribe((Response: any) => {
            if (Response && Response.status === "success") {
                callback(true);
            } else {
                callback(false);
            }
        }, () => {
            callback(false); // Handle error case
        });
}

  public register(name:any,mobile:any,email:any,password:any,otpnew:any){
    let phnvalid= validatePhone(mobile);
    function validatePhone(number:any){
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;  
      return phoneNumberPattern.test(number); 
   }

   this.checkOTP(mobile, otpnew, (isValid) => {
    if(this.otpShow==false){
      $(".errormod").hide();
      this.generateOtp(mobile);
      this.otpShow=true;
      $("#otp").show();
    }
    else if (!isValid) {
      $(".errormod").html("Wrong OTP");
      $(".errormod").show();
    } else {
     
   
 
    if(name==""|| name==null || name==undefined || mobile==""|| mobile==null || mobile==undefined || email==""|| email==null || email==undefined || password==""|| password==null || password==undefined  ){
      $(".errormod").html(" All fields are required");
      $(".errormod").show();
    }
    else if(phnvalid==false){
      $(".errormod").html(" Please check your phone number");
      $(".errormod").show();
    }
    
    else{
      $(".errormod").hide();

      let regdata = {
        data: [
          {
            name: name,
            mobile: mobile,
            email: email,
            password: password,
          },
        ],
      };
      console.log(regdata);
      const headers = { 'Content-type': 'application/json'};
    const body = {
      title: 'Register',
      regdata: regdata,
    };
    this.http.post(GlobalConstants.apiURL+'register', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if(Response.status=="success"){
            localStorage.setItem('customer_id', Response.customer_id);
            localStorage.setItem('customer_name', Response.customer_name);
            this.ngOnInit();
            if(this.slctli.length>0){
              $(".driverprefer").show();
            }
          }
          else{
            $(".errormod").html(Response.message);

            $(".errormod").show();
          }
          }


      });
    }
  }
});
  }
  public bookingsummary(){
    let bsummary = JSON.parse(localStorage.getItem("booking_step1") || '{}');
    this.ttype=bsummary.data[0].trip_type;
    this.btype=bsummary.data[0].booking_type;
    $('#from').val(bsummary.data[0].from);
    $('#to').val(bsummary.data[0].to);
    this.addresses=bsummary.data[0].stops;
  }
  public vehiclechange(){
      if(this.vehicletype=="Mini van" || this.vehicletype=="passenger van"){
        // this.rate=2.05;
        this.calculate();
      }
      else{
        // this.rate=2.05;
        this.calculate();
      }
      
    $('.preloader').show();
      let bsummary = JSON.parse(localStorage.getItem("booking_step1") || '{}');
      const headers = { 'Content-type': 'application/json'};
      const body = {
        title: 'Booking',
        address:bsummary.data[0].from,
        vehicle:this.vehicletype,
        c_id:localStorage.getItem("customer_id"),
      };
      this.http.post(GlobalConstants.apiURL+'driverdeliver', body, { headers })
        .subscribe((Response: any) => {
          // console.log('Response');
          // console.log(Response);
          
    $('.preloader').hide();
        this.slctli = Response;
        this.drivers = this.slctli.list;
        console.log(Response);
          if (Response.length>0) {
            for(let z=0; z<Response.length; z++){
              if(Response[z].favourite=='1'){
                this.checkfavdrvr=true;
                break;
              }
              else{
                this.checkfavdrvr=false;
              }
            }
            $(".driverprefer").show();
          }
          else{
            $(".driverprefer").hide();

          }
  
  
        });
  }
  public ondatechange() {
    this.calculate();
  }
  public preBook(from: any, to: any, triptype: any,booktype:any) {
    this.addstops=0;
    if(from==""|| from==undefined || from==null || to==""|| to==undefined || to==null){
      $("#error5").show();
    }
    else{
      $("#error5").hide();
      
      let stopsdata = {
        data: [],
      };
      if(this.addresses.length >0){
        this.addstops=1;
      }
      if(this.addstops=="1"){
        
        for (let count = 1; this.addresses.length >= count; count = count + 1) {
          if((<HTMLInputElement>document.getElementById('stopname' + count)).value =="" || 
          (<HTMLInputElement>document.getElementById('stopname' + count)).value == undefined ||
          (<HTMLInputElement>document.getElementById('stopname' + count)).value == null){
            $("#error5").show();
            return; 
            
          }
          else{
            $("#error5").hide();
            stopsdata['data'].push({
              'stop-number': count,
              'stop_name': (<HTMLInputElement>document.getElementById('stopname' + count)).value,
              'stop_waiting': $("#stopwait"+count+" select").val(),
            });
          }
         
        }
      }
      
      let schedule_book = {
        data: [
          {
            from: from,
            to: to,
            havestops: this.addstops,
            stops: stopsdata.data,
            trip_type: triptype,
            booking_type:booktype,
          },
        ],
      };
      localStorage.setItem('booking_step1', JSON.stringify(schedule_book));
      this.calculate();
      this.route.navigate(['/booking'])
  .then(() => {
    this.calculate();
    window.location.reload();
    this.calculate();
  });
    }
  }
  public onPassengerchange(passengers:any){
    if(passengers<4){
      $('#sedan').show();
      $('#hatchback').show();
      $('#suv').show();
      $('#outstation').show();
    }
    else if(passengers>3){
      $('#sedan').hide();
      $('#hatchback').show();
      $('#suv').show();
      $('#outstation').show();
      if(this.vehicletype=="sedan"){
        this.vehicletype="";
      }
      
    }
    if(passengers>4){
      $('#sedan').hide();
      $('#hatchback').hide();
      $('#suv').show();
      $('#outstation').show();
      if(this.vehicletype=="hatchback"){
        this.vehicletype="";
      }
    }
    if(passengers>7){
      $('#sedan').hide();
      $('#hatchback').hide();
      $('#suv').hide();
      $('#outstation').show();
      if(this.vehicletype=="suv"){
        this.vehicletype="";
      }
      if(passengers>10){
        $('#sedan').hide();
      $('#hatchback').hide();
      $('#suv').hide();
      $('#outstation').hide();
        if(this.vehicletype=="outstation"){
          this.vehicletype="";
        }
    }
  }
  }

  public onroundtripWaitingchange(waiting:any){
    this.roundtripWaitings=waiting;
    this.calculate(); 
  }
 
  public editbSummary(){
    $('#bsummary').addClass('active');
    $('.active input').prop("disabled", false); 
    $('.active select').prop("disabled", false); 
    $('.active button').prop("disabled", false); 
    $('.active .d-none').removeClass("d-none");
    $('.readonly-wrapper').removeClass("readonly-wrapper");
    $('.booknow').show();
    $('.edit-btn').hide();
  }
   public checkchanged(){
     if($("#checkbx input").prop("checked")==true){
       $(".instantreg").hide();
       $(".instantlogin").show();
       $("#otp").hide();
     }
     else{
      $(".instantreg").show();
      $(".instantlogin").hide();
     }
  }
  public waitingdays(nowdays:any){
    this.waitingdayscharge=(nowdays*480)*this.waitingforMint;
    this.calculate();
  }
  public calculate() {

    

    let step1 = JSON.parse(localStorage.getItem("booking_step1") || '{}');

    
    let ttrip_type = step1.data[0].trip_type;
   
    if (this.vehicletype === "passenger van") {
      this.rate = ttrip_type === "roundtrip" ? 3.25 : 3.25;
    } else {
      this.rate = ttrip_type === "roundtrip" ? 1.85 : 2.65;
    }

    

    let stopsall = step1.data[0].stops;
    var stops = [];
    for (let i = 0; i < stopsall.length; i++) {
      stops.push(stopsall[i].stop_name.toString());
    }
    stops.push(step1.data[0].to);
    var fromstops = [];
    fromstops.push(step1.data[0].from);
    for (let i = 0; i < stopsall.length; i++) {
      fromstops.push(stopsall[i].stop_name.toString());
    }

    const geocoder = new google.maps.Geocoder();

    const service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: fromstops,
        destinations: stops,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
        if (status !== "OK") {
          console.log("Error was: " + status);
        }
        else {
          //  console.log(response);
          let total = 0;
          for (let i = 0; i < response.rows[0].elements.length; i++) {
            if (response.rows[i].elements[i].status == "ZERO_RESULTS" || response.rows[i].elements[i].status == "NOT_FOUND") {
              Swal.fire('Error', 'no routes found', 'error');
              this.route.navigate(['/']);
            }
            else {
              total = total + response.rows[i].elements[i].distance.value;
            }

          }
          if (step1.data[0].trip_type == "roundtrip") {
            total = total + response.rows[0].elements[response.rows[0].elements.length - 1].distance.value;
          }
          this.totalMiles = total * 0.000621371;
          this.totalMiles = (Math.round(this.totalMiles * 100)) / 100.0;
          if (this.totalMiles > 150) {
            if (this.vehicletype === "passenger van") {
              this.rate = 1.50;
            } else {
              this.rate = ttrip_type === "roundtrip" ? 1.50 : 2.50;
            }
          }
          this.charge = this.totalMiles * this.rate;
          this.waitingcharge = this.totalWaititng * this.waitingforMint;
          this.totalPrice = (this.charge) + (this.waitingcharge);
          this.totalPrice = this.totalPrice + this.waitingdayscharge;

          if(ttrip_type=="roundtrip" && this.roundtripWaitings!=undefined){
            this.roundTripwaitingcharge=this.roundtripWaitings* this.waitingforMint;
            console.log("roundtripWaitings"+ this.roundtripWaitings);
            this.totalPrice = this.totalPrice + this.roundTripwaitingcharge;
            }

          this.totalPrice = (Math.round(this.totalPrice * 100)) / 100.0;

          if(ttrip_type === "roundtrip" && this.totalPrice < 40)
            {
              this.totalPrice = 40;
            }
          else if (this.totalPrice < 30) {
            this.totalPrice = 30;
          }

          let today = new Date();
          let pdate=$("#pdate").val();
          if(pdate!=""){
            today=new Date(pdate);
          }
          const currentDate = `${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
          
          // Find if today is a special date
          const specialDate = SPECIAL_DATES.find(special => special.date === currentDate);

          if (specialDate) {
            console.log(`Today is ${specialDate.reason}! Adding special amount: ${specialDate.specialAmount}`);
            this.SpecialDayMessage = `Today is ${specialDate.reason}! A special amount of $${specialDate.specialAmount} is applied.`;
             this.totalPrice += specialDate.specialAmount;
          }
          else{
            this.SpecialDayMessage="";
          }

          this.totalPrice = (this.totalPrice) - (this.promocodeAmount);
          this.chargeRound = (Math.round(this.charge * 100)) / 100.0;
          this.waitingchargeRound = (Math.round(this.waitingcharge * 100)) / 100.0;
          localStorage.setItem("totaldistance", this.totalMiles);
          localStorage.setItem("roundtripWaitings", this.roundtripWaitings.toString());
          localStorage.setItem("roundTripwaitingcharge", this.roundTripwaitingcharge);
          localStorage.setItem("totalprice", this.totalPrice);
          localStorage.setItem("waitingchargeround", this.waitingchargeRound);
          localStorage.setItem("chargeround", this.chargeRound);
        }
      }
    );
  }

 
  
  public bookingstep2(date:any,time:any,passengers:any,returndate:any,returntime:any,nowdays:any,prefferddriver:any,state:any,comment:any){
    
    if (this.isButtonClicked) return;
    this.isButtonClicked = true;
    if(time==undefined){
      time=0;
    }
    if(this.datetime === true && (date === "" || date === null || date === undefined))
    {
      
      $("#step2error").show();
      $("#step2error p").html("Start date and time are required.");
    } 
    else if(this.isoutstation==true && returndate=="" || this.isoutstation==true && returndate==null || this.isoutstation==true && returndate==undefined)
    { 
      $("#step2error").show();
      $("#step2error p").html("Return date is required.");
    }
    else if(this.isoutstation==true && returntime=="" || this.isoutstation==true && returntime==null || this.isoutstation==true && returntime==undefined)
      { 
        $("#step2error").show();
        $("#step2error p").html("Return time is required.");
      }
    else if(passengers=="" || passengers==null || passengers==undefined){
      $("#step2error").show();
      $("#step2error p").html("Please enter number of passengers.");
    }
    else if(this.vehicletype=="" || this.vehicletype==null || this.vehicletype==undefined){
      $("#step2error").show();
      $("#step2error p").html("Please choose vehicle type.");
    }
    else if(this.checked==false){
      $("#step2error").show();
      $("#step2error p").html("Please agree terms & condtions.");
    }
    else if(localStorage.getItem("customer_id") === null){ 

      
      this.roundtripWaitings = this.roundtripWaitings || 0;
      
      localStorage.setItem("totalprice",this.totalPrice); 
      let temp = JSON.parse(localStorage.getItem("booking_step1") || '{}');
      temp.data[0]['start_date']= date;
      temp.data[0]['start_time']= time;
      temp.data[0]['passengers']= passengers;
      temp.data[0]['return_date']= returndate;
      temp.data[0]['return_time']= returntime;
      temp.data[0]['total_price']= this.totalPrice;
      temp.data[0]['total_distance']= this.totalMiles;
      temp.data[0]['vehicle_type']=this.vehicletype;
      temp.data[0]['nowdays']=nowdays;
      temp.data[0]['comment']=comment;
      temp.data[0]['promocode']=this.promocodeApplied;
      temp.data[0]['promocodeval']=this.promocodeAmount;
      temp.data[0]['promocodeid']=this.promocodeID;
      
      temp.data[0]['roundtripWaitings']=this.roundtripWaitings.toString();
      temp.data[0]['roundTripwaitingcharge']=this.roundTripwaitingcharge;

      temp.data[0]['customer_id']=localStorage.getItem("customer_id");
      localStorage.setItem('tempdata', JSON.stringify(temp));
      
        let element: HTMLElement = document.getElementById(
          'customerlogin'
        ) as HTMLElement;
        element.click();
      
    }
    else{
      $("#step2error").hide();
      let bookstep2 = JSON.parse(localStorage.getItem("booking_step1") || '{}');
      // localStorage.removeItem("booking_step1");

      this.roundtripWaitings = this.roundtripWaitings || 0;
      
      localStorage.setItem("totalprice",this.totalPrice);
      bookstep2.data[0]['start_date']= date;
      bookstep2.data[0]['start_time']= time;
      bookstep2.data[0]['passengers']= passengers;
      bookstep2.data[0]['return_date']= returndate;
      bookstep2.data[0]['return_time']= returntime;
      bookstep2.data[0]['total_price']= this.totalPrice;
      bookstep2.data[0]['total_distance']= this.totalMiles;
      bookstep2.data[0]['vehicle_type']=this.vehicletype;
      bookstep2.data[0]['nowdays']=nowdays;
      bookstep2.data[0]['comment']=comment;

      bookstep2.data[0]['roundtripWaitings']=this.roundtripWaitings.toString();
      bookstep2.data[0]['roundTripwaitingcharge']=this.roundTripwaitingcharge;
      
      bookstep2.data[0]['promocode']=this.promocodeApplied;
      bookstep2.data[0]['promocodeval']=this.promocodeAmount;
      bookstep2.data[0]['promocodeid']=this.promocodeID;
      bookstep2.data[0]['driver']=prefferddriver;
      bookstep2.data[0]['d_favourite']=this.checked2;
      bookstep2.data[0]['customer_id']=localStorage.getItem("customer_id");
      bookstep2.data[0]['waiting_charge_minute']=this.waitingforMint;
      bookstep2.data[0]['total_waiting_time']=this.totalWaititng;
      bookstep2.data[0]['total_waiting_charge']=this.waitingcharge;
      bookstep2.data[0]['total_waitingdayscharge']=this.waitingdayscharge;
      bookstep2.data[0]['total_charge_distance']=this.charge;
      bookstep2.data[0]['rate_per_mile']=this.rate;
      localStorage.setItem('booking', JSON.stringify(bookstep2));
      
      // this.route.navigate(['/booking']);
      if(state=="later"){
        const headers = { 'Content-type': 'application/json'};
        const body = {
          title: 'Booking',
          bookingdata: bookstep2,
          favouritetrip: localStorage.getItem("favourite") || '',
          tripname: localStorage.getItem("tripname") || '',
        };
        this.http.post(GlobalConstants.apiURL+'booking', body, { headers })
          .subscribe((Response: any) => {
            if (Response) {
              console.log(Response);
              if(Response.status=="success"){
             
              Swal.fire({
                  title: 'Success',
                  text: "You will receive your ride confirmation and driver details in the registered phone number.",
                  icon: 'success',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  if (result.isConfirmed) {
                    // this.route.navigate(['/my-bookings'])
                    // .then(() => {
                    //   window.location.reload();
                    // });
                    this.route.navigate(['/my-bookings']).then(() => {
                      // Navigation successful
                    }).catch(error => {
                      console.error('Navigation error:', error);
                      this.route.navigate(['/my-bookings'])
                    });
                  }
                })
              }
            }
    
    
          });
      }
      else{
        this.route.navigate(['/payment']).then(() => {
          // Navigation successful
        }).catch(error => {
          console.error('Navigation error:', error);
          this.route.navigate(['/payment'])
        });
      }
     
    }
}
}