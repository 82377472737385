<section class="banner">
  <div class="container">
    <div class="row">

      <div class="col-md-6  d-md-flex align-items-center">
        <div class="banner-cnt">

          <!-- <p>Quisque sollicitudin feugiat risus, eu posuere ex euismod eu. </p> -->
          <h1>
            Book your ride with Yoder Toter now
          </h1>
          <h2>Call Our Call Center 
          
            <small>
              (Monday–Friday: 7 A.M–5 P.M, Saturday: 7 A.M–2 P.M)
            </small>

          </h2>
          <h3>330-474-9507</h3>
          <div class="d-flex">
            <a href="tel:330-474-9507">
              <button mat-raised-button class="call-now">
                Call Now
              </button>
            </a>
            <button mat-raised-button class="call-now book" routerLink="#bookfrom">
              Book Now (ASAP)
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 pt-5 pb-5 banner-form" id="bookform">
        <div class="row">
          <div class="col-12">
            <h1>Book Your Ride</h1>
          </div>
        </div>
        <mat-tab-group [selectedIndex]="tabind">
          <mat-tab label="One Way">
            <div class="row m-0 pt-3">

              <div class="col-md-12">
                <button mat-button class="cfft mb-2" *ngIf="logedin==true;" (click)="callFavourite()">Choose From
                  Favourite Trip</button>
              </div>
              <div class="col-12" *ngIf="isShown">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Favourite Trips</mat-label>
                  <mat-select (selectionChange)="favchanged(favslct.value)" #favslct>
                    <div *ngFor="let e of li">
                      <mat-option *ngIf="e.t_favourite=='1'" [value]="e.t_id">
                        {{e.t_name}}
                      </mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>From</mat-label>
                  <input matInput class="w-100 formfrom" #from1 matGoogleMapsAutocomplete strictBounds country="us">
                </mat-form-field>
              </div>
              <div class="col-12 pb-2">
                <label><b>Would you like to add intermediate stops in your ride?</b></label>
                <br>
                <mat-radio-group [(ngModel)]="addstops" aria-label="">
                  <mat-radio-button value="1">Yes</mat-radio-button>
                  <mat-radio-button value="0" class="ml-2">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-12 overflow-hidden" *ngIf="addstops=='1'">
                <div *ngFor="let address of addresses; let i = index;" id="stopid{{i}}">
                  <div class="row">
                    <div class="col-8 pr-1">

                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Stop {{i+1}}</mat-label>
                        <input matInput [(ngModel)]="address.address" matGoogleMapsAutocomplete country="us"
                          id="stopname{{i+1}}" name="address_{{address.id}}" value="{{addresses[i].stop_name}}">
                      </mat-form-field>
                    </div>
                    <div class="col-4 pr-1">
                      <mat-form-field appearance="outline" class="w-90" id="stopwait{{i+1}}">
                        <mat-label>Waiting Time</mat-label>
                        <select matNativeControl value="{{addresses[i].stop_waiting || 15}}">

                          <option value="15">
                            15 Minutes
                          </option>
                          <option value="30">
                            30 Minutes
                          </option>
                          <option value="45">
                            45 Minutes
                          </option>
                          <option value="60">
                            1 Hour
                          </option>
                          <option value="75">
                            1.15 Hours
                          </option>
                          <option value="90">
                            1.30 Hours
                          </option>
                          <option value="105">
                            1.45 Hours
                          </option>
                          <option value="120">
                            2 Hours
                          </option>
                          <option value="135">
                            2.15 Hours
                          </option>
                          <option value="150">
                            2.30 Hours
                          </option>
                          <option value="165">
                            2.45 Hours
                          </option>
                          <option value="180">
                            3 Hours
                          </option>

                        </select>
                      </mat-form-field>
                    </div>
                    <div class="col-0 text-right pl-0">
                      <button mat-raised-button type="button" class="btn-remove" (click)="removeAddress(i)">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <label>Enter waiting time if driver want to wait</label><br>
                <button mat-raised-button type="button" class="addbtn mb-2 text-center" (click)="addAddress()">Add Stop
                  <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon></button>

              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>To</mat-label>
                  <input matInput class="w-100 formto" #to1 matGoogleMapsAutocomplete country="us">
                </mat-form-field>
              </div>
              <div class="col-12 text-center mb-3">
                <div class="row p-0 m-0">
                  <div class="col-md-12">
                    <mat-checkbox class="example-margin" [(ngModel)]="favourite">Add this trip to
                      favourites</mat-checkbox>
                  </div>

                  <div class="col-md-12">
                    <mat-form-field appearance="outline" class="w-100" *ngIf="favourite">
                      <mat-label>Trip Name</mat-label>
                      <input matInput class="w-100" [(ngModel)]="tripname">
                    </mat-form-field>
                  </div>

                </div>
              </div>
              <div class="col-md-6 pl-0 pr-1">
                <button mat-raised-button class="btn1 btn2"
                  (click)="preBook(from1.value,to1.value,addstops,'oneway','schedule');">Schedule Later</button>

              </div>
              <div class="col-md-6 pr-0 pl-1">
                <button mat-raised-button class="btn1"
                  (click)="preBook(from1.value,to1.value,addstops,'oneway','book');">Book Now (ASAP)</button>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Round Trip">
            <div class="row m-0 pt-3">

              <div class="col-md-12">
                <button mat-button class="cfft mb-2" *ngIf="logedin==true;" (click)="callFavourite()">Choose From
                  Favourite Trip</button>
              </div>
              <div class="col-12" *ngIf="isShown">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Favourite Trips</mat-label>
                  <mat-select (selectionChange)="favchanged(favslct.value)" #favslct>
                    <div *ngFor="let e of li">
                      <mat-option *ngIf="e.t_favourite=='1'" [value]="e.t_id">
                        {{e.t_name}}
                      </mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>From</mat-label>
                  <input matInput #from2 class="w-100 formfrom" matGoogleMapsAutocomplete country="us">
                </mat-form-field>
              </div>
              <div class="col-12 pb-2">
                <label><b>Would you like to add intermediate stops in your ride?</b></label>
                <br>
                <mat-radio-group [(ngModel)]="addstops" aria-label="">
                  <mat-radio-button value="1">Yes</mat-radio-button>
                  <mat-radio-button value="0" class="ml-2">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-12 overflow-hidden" *ngIf="addstops=='1'">
                <div *ngFor="let address of addresses; let i = index;" id="stopid{{i}}">
                  <div class="row">
                    <div class="col-8 pr-1">

                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Stop {{i+1}}</mat-label>
                        <input matInput id="stopnameb{{i+1}}" [(ngModel)]="address.address" matGoogleMapsAutocomplete
                          country="us" name="address_{{address.id}}" value="{{addresses[i].stop_name}}">
                      </mat-form-field>
                    </div>
                    <div class="col-4 pr-1">
                      <mat-form-field appearance="outline" class="w-90" id="stopwaitb{{i+1}}">
                        <mat-label>Waiting Time</mat-label>
                        <select matNativeControl value="{{addresses[i].stop_waiting || 15}}">

                          <option value="15">
                            15 Minutes
                          </option>
                          <option value="30">
                            30 Minutes
                          </option>
                          <option value="45">
                            45 Minutes
                          </option>
                          <option value="60">
                            1 Hour
                          </option>
                          <option value="75">
                            1.15 Hours
                          </option>
                          <option value="90">
                            1.30 Hours
                          </option>
                          <option value="105">
                            1.45 Hours
                          </option>
                          <option value="120">
                            2 Hours
                          </option>
                          <option value="135">
                            2.15 Hours
                          </option>
                          <option value="150">
                            2.30 Hours
                          </option>
                          <option value="165">
                            2.45 Hours
                          </option>
                          <option value="180">
                            3 Hours
                          </option>

                        </select>
                      </mat-form-field>
                    </div>
                    <div class="col-0 text-right pl-0">
                      <button mat-raised-button type="button" class="btn-remove" (click)="removeAddress(i)">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <label>Enter waiting time if driver want to wait</label><br>
                <button mat-raised-button type="button" class="addbtn mb-2 text-center" (click)="addAddress()">Add Stop
                  <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon></button>

              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>To</mat-label>
                  <input matInput class="w-100" class="formto" #to2 matGoogleMapsAutocomplete country="us">
                </mat-form-field>
              </div>
              <div class="col-12 text-center mb-3">
                <div class="col-12 text-center mb-3">
                  <div class="row p-0 m-0">
                    <div class="col-md-12">
                      <mat-checkbox class="example-margin" [(ngModel)]="favourite">Add this trip to
                        favourites</mat-checkbox>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="w-100" *ngIf="favourite">
                        <mat-label>Trip Name</mat-label>
                        <input matInput class="w-100" [(ngModel)]="tripname">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pl-0 pr-1">
                <button mat-raised-button class="btn1 btn2"
                  (click)="preBook2(from2.value,to2.value,addstops,'roundtrip','schedule');">Schedule Later</button>

              </div>
              <div class="col-md-6 pr-0 pl-1">
                <button mat-raised-button class="btn1"
                  (click)="preBook2(from2.value,to2.value,addstops,'roundtrip','book');">Book Now (ASAP)</button>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Long Distance">
            <div class="row m-0 pt-3">

              <div class="col-md-12">
                <button mat-button class="cfft mb-2" *ngIf="logedin==true;" (click)="callFavourite()">Choose From
                  Favourite Trip</button>
              </div>
              <div class="col-12" *ngIf="isShown">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Favourite Trips</mat-label>
                  <mat-select (selectionChange)="favchanged(favslct.value)" #favslct>
                    <div *ngFor="let e of li">
                      <mat-option *ngIf="e.t_favourite=='1'" [value]="e.t_id">
                        {{e.t_name}}
                      </mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>From</mat-label>
                  <input matInput #from3 class="w-100 formfrom" matGoogleMapsAutocomplete country="us">
                </mat-form-field>
              </div>
              <div class="col-12 pb-2">
                <label><b>Would you like to add intermediate stops in your ride?</b></label>
                <br>
                <mat-radio-group [(ngModel)]="addstops" aria-label="">
                  <mat-radio-button value="1">Yes</mat-radio-button>
                  <mat-radio-button value="0" class="ml-2">No</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-12 overflow-hidden" *ngIf="addstops=='1'">
                <div *ngFor="let address of addresses; let i = index;" id="stopid{{i}}">
                  <div class="row">
                    <div class="col-8 pr-1">

                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Stop {{i+1}}</mat-label>
                        <input matInput id="stopnamec{{i+1}}" [(ngModel)]="address.address" matGoogleMapsAutocomplete
                          country="us" name="address_{{address.id}}" value="{{addresses[i].stop_name}}">
                      </mat-form-field>
                    </div>
                    <div class="col-4 pr-1">
                      <mat-form-field appearance="outline" class="w-90" id="stopwaitc{{i+1}}">
                        <mat-label>Waiting Time</mat-label>
                        <select matNativeControl value="{{addresses[i].stop_waiting || 15}}">

                          <option value="15">
                            15 Minutes
                          </option>
                          <option value="30">
                            30 Minutes
                          </option>
                          <option value="45">
                            45 Minutes
                          </option>
                          <option value="60">
                            1 Hour
                          </option>
                          <option value="75">
                            1.15 Hours
                          </option>
                          <option value="90">
                            1.30 Hours
                          </option>
                          <option value="105">
                            1.45 Hours
                          </option>
                          <option value="120">
                            2 Hours
                          </option>
                          <option value="135">
                            2.15 Hours
                          </option>
                          <option value="150">
                            2.30 Hours
                          </option>
                          <option value="165">
                            2.45 Hours
                          </option>
                          <option value="180">
                            3 Hours
                          </option>

                        </select>
                      </mat-form-field>
                    </div>
                    <div class="col-0 text-right pl-0">
                      <button mat-raised-button type="button" class="btn-remove" (click)="removeAddress(i)">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <label>Enter waiting time if driver want to wait</label><br>
                <button mat-raised-button type="button" class="addbtn mb-2 text-center" (click)="addAddress()">Add Stop
                  <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon></button>

              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>To</mat-label>
                  <input matInput #to3 class="formto w-100" matGoogleMapsAutocomplete country="us">
                </mat-form-field>
              </div>
              <div class="col-12 text-center mb-3">
                <div class="col-12 text-center mb-3">
                  <div class="row p-0 m-0">
                    <div class="col-md-12">
                      <mat-checkbox class="example-margin" [(ngModel)]="favourite">Add this trip to
                        favourites</mat-checkbox>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="w-100" *ngIf="favourite">
                        <mat-label>Trip Name</mat-label>
                        <input matInput class="w-100" [(ngModel)]="tripname">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pl-0 pr-1">
                <button mat-raised-button class="btn1 btn2"
                  (click)="preBook3(from3.value,to3.value,addstops,'outstation','schedule');">Schedule Later</button>

              </div>
              <div class="col-md-6 pr-0 pl-1">
                <button mat-raised-button class="btn1"
                  (click)="preBook3(from3.value,to3.value,addstops,'outstation','book');">Book Now (ASAP)</button>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <div class="row" id="error">
          <div class="col-12">
            <p>
              All fields are required.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="how-it-works pt-5 pb-5 blocks">
  <div class="container text-center">
    <div class="row">
      <div class="col-md-12">
        <h2>
          How it works?
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-6 col-lg first">
        <img src="assets/images/taxi-icon.png" class="img-fluid">
        <h3>Book a ride</h3>
        <p>Select your destination and the type of vehicle you’d like to ride in.</p>
      </div>
      <div class="col-6 col-md-6 col-lg">
        <img src="assets/images/track-icon.png" class="img-fluid">
        <h3>Track your cab</h3>
        <p>Track the arrival of your cab on a map in real time.</p>
      </div>
      <div class="col-6 col-md-6 col-lg">
        <img src="assets/images/shield-icon.png" class="img-fluid">
        <h3>Arrive safely</h3>
        <p>Reach your destination in a comfortable ride.</p>
      </div>
      <div class="col-6 col-md-6 col-lg">
        <img src="assets/images/e-bill-icon.png" class="img-fluid">
        <h3>Get an e-bill</h3>
        <p>Get an e-bill receipt on your.</p>
      </div>
      <div class="col-6 col-md-6 col-lg last">
        <img src="assets/images/feedback-icon.png" class="img-fluid">
        <h3>Feedback</h3>
        <p>Instant feedback will help us to improve for your future rides with us.</p>
      </div>
    </div>
    <div class="row vhtyperow">
      <div class="col-md-12 pt-5">
        <h2 class="mb-1">Let's get you en-route </h2>
        <p class="mb-5">We have a range of vehicle types to choose from.</p>
      </div>

      <div class="col-6 col-md">
        <div class="veh_bx">
          <img src="assets/images/sedan.png">
          <h3>Sedan</h3>
        </div>
      </div>
      <!-- <div class="col-6 col-md">
            <img src="assets/images/hatchback.png">
            <h3>Hatchback</h3>
        </div> -->
      <div class="col-6 col-md">
        <div class="veh_bx">
          <img src="assets/images/suv.png">
          <h3>SUV</h3>
        </div>
      </div>
      <div class="col-6 col-md">
        <div class="veh_bx">
          <img src="assets/images/outstation.png">
          <h3>Mini Van</h3>
        </div>
      </div>
      <div class="col-6 col-md">
        <div class="veh_bx">
          <img src="assets/images/passenger-van.png">
          <h3>Passenger Van</h3>
        </div>
      </div>
      <div class="col-6 col-md">
        <div class="veh_bx">
          <img src="assets/images/truck.png">
          <h3>Truck</h3>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="driver-invite pt-5 pb-5 pt-md-0 pb-md-0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 pl-0">
        <img src="assets/images/taxi.png" class="img-fluid">
      </div>
      <div class="col-md-6 pr-md-5 pt-md-4 pb-md-5 pt-0 pb-0 mt-md-5">
        <h3>Register Your Car & Become Our Driving Partner</h3>
        <h2>
          Do you want to earn with us?
        </h2>
        <p>
          Let us handle the marketing for you. You are now a partner with the premier name in Amish areas of the
          country. Easy to accept or reject rides depending on your schedule.
        </p>
        <ul>
          <li>
            No fee
          </li>
          <li>
            Weekly payment
          </li>

          <li>
            Fixed price
          </li>
          <li>
            Good application
          </li>
          <li>
            100% Flexible Schedule
          </li>
        </ul>
        <a href="https://yodertoter.com/yoderadmin/driver/">
          <button mat-raised-button class="call-now">
            Become Our Driving Partner
          </button>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="contact-sec pt-5 pb-5 blocks" id="about">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>
          We are available
          <br><span> <small>(Monday–Friday: 7 A.M–5 P.M, Saturday: 7 A.M–2 P.M)</small></span>
        </h2>
      </div>
      <div class="col-md-6">
        <img src="assets/images/enquiry.png" class="img-fluid">
      </div>
      <div class="col-md-6">
        <p>
          Life happens. Many times, things happen beyond 8 to 5 Monday through Friday and you need a ride. Our drivers
          all keep different schedules and we’ll get one for you as soon as is safely possible.
        </p>
        <a href="tel:330-474-9507">
          <button mat-raised-button class="call-now">
            Call Now
          </button>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="tax-call pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 pt-0 pt-md-5 pb-5 d-flex align-items-center">
        <div class="inerbox">
          <h3>
            Call us to Book a Ride
          </h3>
          <h2>
            330-474-9507
          </h2>
          <p>
            The operator will call back immediately and report the cost of travel
          </p>
        </div>
      </div>
      <div class="col-lg-6">
        <img src="assets/images/calling-taxi.jpg" class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="call-now-sec pt-5 pb-5 text-center">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4>Call Us | Monday–Friday: 7 A.M–5 P.M, Saturday: 7 A.M–2 P.M</h4>
        <h2>
          Call now and book
          <br>our taxi for your next ride
        </h2>
        <a href="tel:330-474-9507">
          <button mat-raised-button class="call-now">
            Call Now
          </button>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="testimonials pt-5 pb-5" id="testimonials">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Testimonials</h2>
      </div>
      <div class="col-12">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="testi">
              <p>
                "We had to get to a local hospital and Yoder Toter was able to help us out."
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testi">
              <p>
                "Planned a great day of shopping and Yoder Toter made it easy."
              </p>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="testi">
              <p>
                "We had to get to a local hospital and Yoder Toter was able to help us out."
              </p>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>