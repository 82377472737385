
<!--Author      : @arboshiki-->
<div id="invoice">

    <div class="toolbar hidden-print">
        <div class="text-right">
            <button id="printInvoice" class="btn btn-info" (click)="print()">   <mat-icon>picture_as_pdf</mat-icon> Print</button>
 
        </div>
        <hr>
    </div>
    <div *ngFor="let e of li;">
    <div class="invoice overflow-auto" *ngIf="e.t_id == pageid">
        <div style=" min-width: 600px;
        max-width: 1000px;
        margin: 0px auto;" id="contentToConvert">
            <header>
                <div class="row" style="height: 40px">
                    <div class="col">
                        <h2 style="font-size: 2em;font-weight: 800;">Yoder Toter</h2>
                    </div>
                    <div class="col company-details" style="visibility: hidden;">
                        <h2 class="name">
                            {{li2.c_name}}
                        </h2>
                        <div> {{li2.c_address}}</div>
                        <div> {{li2.c_mobile}}</div>
                        <div> {{li2.c_email}}</div>
                    </div>
                </div>
            </header>
            <main>
                <div class="row contacts">
                    <div class="col invoice-to">
                        <div class="text-gray-light">INVOICE TO:</div>
                        <h2 class="to" style="text-transform: capitalize;">{{li2.c_name}}</h2>
                        <div class="address">{{li2.c_address}}</div>
                        <div class="address">{{li2.c_mobile}}</div>
                        <div class="email">{{li2.c_email}}</div>
                    </div>
                    <div class="col invoice-details" style="margin-right:50px">
                        <h2 class="invoice-id" style="
                        font-family: sans-serif !important;
                    ">#{{e.t_in_id}}</h2>
                        <div class="date">Date of Invoice: {{e.t_created_date |  date:'MM/dd/yyyy'}}</div>
                        <!-- <div class="date">Due Date: 30/10/2018</div> -->
                    </div>
                </div>
                <table border="0" cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th class="text-left">Details</th>
                            <th class="text-right">Charge</th>
                            <th class="text-right"></th>
                            <th class="text-right">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="no">1</td>
                            <td class="text-left">
                                <p>From : {{e.t_trip_fromlocation}}</p>
                                <p>To :{{e.t_trip_tolocation}}</p>
                            <p>Stops :  {{checkstops(e.t_intermediate_stops)}}</p>
                            </td>
                            <td class="unit">${{e.rate_per_mile}}/Mile</td>
                            <td class="qty">{{e.t_totaldistance}} Miles</td>
                            <td class="total">${{e.total_charge_distance}}</td>
                        </tr>
                        <tr>
                            <td class="no">2</td>
                            <td class="text-left">
                                <p>Total Waiting Time : </p>
                            </td>
                            <td class="unit"></td>
                            <td class="qty">{{e.total_waiting_time}} Minutes</td>
                            <td class="total">${{(e.total_waiting_time * e.waiting_charge_minute)}}</td>
                        </tr>
                    <tr>
                        <td class="no">3</td>
                        <td class="text-left">
                            <p>Days of Waiting : </p>
                        </td>
                        <td class="unit"></td>
                        <td class="qty">
                            <div *ngIf="e.no_days == null || e.no_days == undefined || e.no_days == '' ; else nodays">
                                0 Days
                              </div>
                              
                              <ng-template #nodays>
                                {{e.no_days}} Days
                              </ng-template>
                        </td>
                        <td class="total">${{(e.no_days *(1440*e.waiting_charge_minute))}}</td>
                    </tr>
                    <tr>
                        <td class="no">4</td>
                        <td class="text-left">
                            <p>Additional Charges : </p>
                        </td>
                        <td class="unit"></td>
                        <td class="qty">
                          
                        </td>
                        <td class="total">${{(e.t_additional_charge)}}</td>
                    </tr>
                    <tr *ngIf="e.roundtrip_waiting_amount!=undefined">
                        <td class="no">5</td>
                        <td class="text-left">
                            <p>Roundtrip Waiting Charges : </p>
                        </td>
                        <td class="unit"></td>
                        <td class="qty">{{e.roundtrip_waiting_time}} Minutes</td>
                        <td class="total">${{(e.roundtrip_waiting_amount)}}</td>
                    </tr>
                      
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="2">SUBTOTAL</td>
                            <td> ${{addtotal(e.t_additional_charge,e.t_trip_amount)}}</td>
                        </tr>
                        <!-- <tr>
                            <td colspan="2"></td>
                            <td colspan="2">TAX 25%</td>
                            <td>$1,300.00</td>
                        </tr> -->
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="2">GRAND TOTAL</td>
                            <td> ${{addtotal(e.t_additional_charge,e.t_trip_amount)}}</td>
                        </tr>
                    </tfoot>
                </table>
                <!-- <div class="thanks">Thank you!</div> -->
                <div class="notices d-none">
                    <div>NOTICE:</div>
                    <div class="notice">A finance charge of 1.5% will be made on unpaid balances after 30 days.</div>
                </div>
            </main>
            <footer>
                Invoice was created on a computer and is valid without the signature and seal.
            </footer>
        </div>
        <!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
        <div></div>
    </div>
    </div>
</div>